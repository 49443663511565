// 刘志坤 V2.3.0
<template>
  <div class="page-add business-role-add" style="height: auto">
    <a-form ref="formRef" :rules="rules" :model="modalVal" class="form-add-big">
      <a-form-item label="角色名称" name="roleName" class="form-item">
        <a-input
          :maxlength="20"
          placeholder="请输入角色名称"
          v-model:value="modalVal.roleName"
          class="form-item-width"
        />
      </a-form-item>
      <a-form-item label="角色说明" name="mark" class="form-item">
        <a-textarea
          @pressEnter="e => lineFeed(e)"
          v-model:value="modalVal.mark"
          placeholder="请输入角色说明"
          :maxlength="100"
          :autoSize="{ minRows: 3 }"
          class="form-item-width"
        ></a-textarea>
      </a-form-item>
      <a-form-item label="默认数据权限" name="data" class="form-item form-long">
        <dataPermissions ref="dataPermissions" width="727px" />
      </a-form-item>

      <a-form-item>
        <div class="check-box">
          <div class="add-check-box" v-if="checkPeoples.length" @click="addPeople">
            <i class="iconfont icon-tianjiatiaojian"></i>添加授权人员
          </div>
          <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane key="1" tab="功能授权">
              <div style="background-color: #f7f7f7; width: 863px; padding: 19px 0 25px 50px">
                <a-form :rules="rules">
                  <a-form-item label="权限" name="power" class="powers" style="margin-bottom: 0px">
                    <div style="padding: 39px 0 0 10px">
                      <h4 class="top-title">功能权限</h4>
                      <a-checkbox v-model:checked="checkAll" @change="onCheckAllChange" style="margin-top: 7px"
                        >全部</a-checkbox
                      >
                      <a-tree
                        v-model:checkedKeys="modalVal.power"
                        checkable
                        :expanded-keys="expandedKeys"
                        :auto-expand-parent="autoExpandParent"
                        :selected-keys="selectedKeys"
                        :tree-data="permissionList"
                        @expand="onExpand"
                        @check="onCheck"
                        :replaceFields="{ children: 'child', title: 'name', key: 'id' }"
                        class="form-item-width show-scrollbar te-power"
                        style="padding-left: 26px; width: 540px"
                      >
                        <template #switcherIcon>
                          <LeftOutlined />
                        </template>
                      </a-tree>
                    </div>
                  </a-form-item>
                </a-form>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="人员授权">
              <div
                :class="['people-box', !checkPeoples.length && 'add-box']"
                :style="{ padding: pagination.pageSize == 5 ? '20px 0' : '20px 0 40px' }"
              >
                <div class="no-check-box" v-if="!checkPeoples.length" @click="addPeople">
                  <i class="iconfont icon-tianjiatiaojian"></i>添加授权人员
                </div>
                <div v-else class="table-box">
                  <a-table
                    :columns="column"
                    :dataSource="checkPeoples"
                    rowKey="dataId"
                    ref="tableRef"
                    :scroll="{ x: true }"
                    :pagination="selectedRowKeys.length ? false : pagination"
                    @change="handleTanChange"
                    :row-selection="{
                      selectedRowKeys: selectedRowKeys,
                      onChange: onSelectChange,
                      onSelectAll: onSelectAll
                    }"
                  >
                    <template #id="{ index }">{{ (pagination.index - 1) * pagination.pageSize + index + 1 }}</template>
                    <template #dataName="{ text }">
                      <!-- {{ pagination.index }}-{{ pagination.pageSize }} -->
                      <span v-if="text.length <= 6">{{ text }}</span>
                      <a-popover v-else>
                        <template #content>
                          <span>{{ text }}</span>
                        </template>
                        <span class="text-ellipsis text-6">{{ text }}</span>
                      </a-popover>
                    </template>
                    <!-- 部门 -->
                    <template #departmentNames="{ text }">
                      <div v-if="text.length < 10">{{ text }}</div>
                      <a-popover v-else>
                        <template #content>
                          {{ text }}
                        </template>
                        <div class="text-140 text-ellipsis">{{ text }}</div>
                      </a-popover>
                    </template>
                    <!-- 当前数据范围 -->
                    <template #rangData="{ text }">
                      <!-- {{ text.showList }} -->
                      <div class="text-168 text-ellipsis" v-if="text.ellipsisText.length <= 12">
                        <template v-for="(item, index) in text.showList" :key="item">
                          <span>{{ item.dataName }}</span
                          ><span v-if="index != text.showList.length - 1">、</span>
                        </template>
                      </div>
                      <a-popover v-else>
                        <template #content>
                          <template v-for="(item, index) in text.showList" :key="item">
                            <span>{{ item.dataName }}</span
                            ><span v-if="index != text.showList.length - 1">、</span>
                          </template>
                        </template>
                        <div class="text-168 text-ellipsis">
                          <template v-for="(item, index) in text.showList" :key="item">
                            <span>{{ item.dataName }}</span
                            ><span v-if="index != text.showList.length - 1">、</span>
                          </template>
                        </div>
                      </a-popover>
                    </template>
                    <!-- 设置管理/查看范围 -->
                    <template #action="{ record}">
                      <div class="check-btn" @click="setRangData('set', record)">设置</div>
                      <div class="check-btn" @click="setRangData('remove', record)">移除</div>
                    </template>
                  </a-table>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
          <a-button class="operation-button" v-if="activeKey == '2' && selectedRowKeys.length" @click="batchSetting(1)"
            >批量设置</a-button
          >
          <a-button class="operation-button" v-if="activeKey == '2' && selectedRowKeys.length" @click="batchSetting(2)"
            >批量移除</a-button
          >
        </div>
      </a-form-item>

      <a-form-item label="" class="form-item">
        <section style="margin: 30px 0 0 320px;">
          <a-button class="cancel-btn" @click="handleCancel" style="margin-right: 16px;">取消</a-button>
          <a-button class="submit-btn" @click="comfirmAdd" :loading="loading">确定</a-button>
        </section>
      </a-form-item>
    </a-form>
    <Power
      v-model:visible="visible"
      :selectID="modalVal.deptIds"
      :organizationalList="organizationalList"
      @selectedDepartment="selectedDepartment"
    />
    <!-- 角色人员 组织架构信息 -->
    <RangeModal
      :selectData="departmentList"
      :visible="RangeModalVisible"
      :title="popText"
      @close="handledRangeVisible"
      @checkData="checkData"
      ref="sonRef"
      :checkAll="true"
      :type="10"
    />

    <a-modal
      :visible="modalShow"
      centered
      title="添加权限人"
      width="655px"
      class="global-range-modal"
      @ok="submitModel"
      @cancel="closeModal"
    >
      <GlobalSelector
        :visible="modalShow"
        :selectorInfo="selectorInfo"
        :renderList="checkList"
        :selectedList="checkPeoples"
        @getSearchInfo="getSearchInfo"
        @toNext="tolowerMdel"
        @changeData="changeData"
        ref="globalSelector"
      />
    </a-modal>

    <a-modal :visible="rangeModalShow" centered title="设置" width="727px" @cancel="rangeCancel" @ok="rangeOk">
      <div class="headlin-text">
        可灵活设置当前成员组织架构的可见性
      </div>
      <dataPermissions ref="modalDataPermissions" width="700px" :visible="rangeModalShow" :compDetail="setRangeItem" />
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, toRefs, onMounted, watch, createVNode, computed } from 'vue'
import { useRouter } from 'vue-router'
import { LeftOutlined, CloseOutlined, DownOutlined, ExclamationOutlined } from '@ant-design/icons-vue'
import Power from './components/power.vue'
import PersonAuthorization from './components/personAuthorization'
import {
  addNewRole,
  getPermissionList,
  getDepartmentShow,
  verifyRoleStaff,
  transformPeople,
  getDepartmentOrStaff,
  getSearchData
} from '@/apis/businessManage'
import { formateListData } from '@/utils/selectorListFormate.js'
import '@/style/page-add.scss'
import { cmsNotice } from '@/utils/utils'
import { rolePeopleColumns } from '../columns'
import RangeModal from '@/components/rangeModal'
import GlobalSelector from '@/components/GlobalSelector'
import { Modal } from 'ant-design-vue'
import dataPermissions from './components/dataPermissions.vue'

export default defineComponent({
  components: {
    Power,
    LeftOutlined,
    CloseOutlined,
    DownOutlined,
    RangeModal,
    PersonAuthorization,
    GlobalSelector,
    dataPermissions
  },
  setup(props, ctx) {
    const { emit } = ctx
    const formRef = ref()
    const router = useRouter()
    const modalVal = reactive({
      roleName: undefined,
      mark: undefined,
      power: [],
      deptIds: [],
      dataScope: 0,
      roleStaffList: [] //角色人员
    })
    const modalConfig = reactive({
      modalShow: false,
      // 弹窗配置
      selectorInfo: {
        isSingleChoice: false, //[是否为单选]
        isShowSelectedList: true, //[是否展示已选的列表]
        isShowBreadcrumb: true, //[是否显示面包屑]
        placeholder: '请输入员工姓名或部门',
        abelCheckAll: true,
        ableSelectDep: true,
        ableChangeData: true
      },
      checkList: [], // 所有可选人员
      list: []
    })
    const defaultRoleIds = ref([])
    const changeTotal = computed(() => {
      return state.checkPeoples.length
    })
    const state = reactive({
      selectedRowKeys: [],
      expandedKeys: [],
      autoExpandParent: false,
      selectedKeys: [],
      permissionList: [],
      loading: false,
      visible: false,
      organizationalList: [],
      departmentNames: '',
      elecId: undefined, // 文件管理id
      departmentList: [],
      RangeModalVisible: false,
      popText: '请选择角色人员',
      activeKey: '1',
      checkedIds: [], // 实际选择的人员id数组
      defaultDataPermissions: [], // 默认数据权限
      checkPeoples: [],
      column: rolePeopleColumns,
      setRangeItem: {}, // 设置当前项
      pagination: {
        pageSize: 5,
        total: changeTotal,
        current: 1,
        index: 1,
        'show-size-changer': true,
        'show-quick-jumper': true,
        'disabled-size-changer': true,
        'page-size-options': ['5', '10', '30', '50']
      },
      rangeModalShow: false // 设置范围弹窗
    })
    const selectorType = ref(1)

    // TODO：人员授权批量设置
    const onSelectChange = selectedRowKeys => {
      console.log('选中的行', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }
    const onSelectAll = (selected, selectedRows, changeRows) => {
      if (selected) {
        // 全选
        state.selectedRowKeys = []
        console.log('列表所有数据', state.checkPeoples)
        state.checkPeoples.forEach(i => {
          state.selectedRowKeys.push(i.dataId)
        })
      }
    }
    const batchSetting = type => {
      switch (type) {
        case 1:
          //批量设置
          state.setRangeItem = {}
          state.rangeModalShow = true
          break
        case 2:
          //批量移除
          Modal.confirm({
            title: '是否移除！',
            icon: createVNode(ExclamationOutlined),
            content: '确认将所选成员移出当前列表？',
            okText: '确定',
            cancelText: '取消',
            centered: true,
            onCancel() {},
            onOk() {
              console.log('选中的信息', state.selectedRowKeys)
              state.selectedRowKeys.forEach(item => {
                console.log('选中的每一项', item)
                state.checkPeoples = state.checkPeoples.filter(i => i.dataId != item)
              })
              state.selectedRowKeys = []
              console.log('移除过后的人员有哪些', state.checkPeoples)
            }
          })
          break
      }
    }

    const checkAll = ref(false)
    const rules = ref()
    rules.value = {
      roleName: [
        {
          required: true,
          message: '请输入角色名称'
        }
      ],
      power: [
        {
          type: 'array',
          required: true,
          message: ''
        }
      ]
    }
    // 人员授权模块实例
    const globalSelector = ref(null)
    //确定
    const comfirmAdd = async () => {
      console.log('选择人员数据', modalVal.roleStaffList, state.checkedIds)
      formRef.value
        .validate()
        .then(async () => {
          // await transformData()
          // if (state.checkedIds.length) {
          //   const { success, data } = await verifyRoleStaff({ roleStaffList: state.checkedIds })
          //   if (success && !data) {
          //     Modal.confirm({
          //       title: '提示',
          //       icon: createVNode(ExclamationOutlined),
          //       content: '部分人员已经绑定了角色是否需要替换？替换后将失去原有的权限！',
          //       centered: true,
          //       onOk() {
          //         addRole()
          //       }
          //     })
          //     return
          //   }
          // }
          addRole()
        })
        .catch(() => (state.loading = false))
    }

    // 处理部门转化人员
    const transformData = async () => {
      // if (!globalSelector.value && !state.departmentList.length) return
      // let list = globalSelector.value ? globalSelector.value.okEvent() : state.departmentList
      if (!globalSelector.value) return []
      let list = globalSelector.value.okEvent()
      // console.log('选中的数据', list);
      if (!list.length) return []
      // state.checkedIds = []
      let deptIds = []
      let peopleList = []
      list.map(i => {
        if (i.dataType == 2) {
          deptIds.push(i.dataId)
        } else {
          peopleList.push(i)
        }
      })
      const res = await transformPeople({ deptIds })
      if (res.success && res.data?.length) {
        let newList = formateListData({ data: { staffList: res.data } })
        // res.data
        newList.forEach(i => {
          peopleList.push(i)
        })
        peopleList = deduplicateObjects(peopleList, 'dataId')
      }
      peopleList = peopleList.map(i => {
        return {
          departmentNames: i.departmentNames || i.departmentName,
          ...i
        }
      })
      console.log('转化后的人员', peopleList)
      return peopleList
    }

    // 关闭弹窗
    const closeModal = () => {
      modalConfig.modalShow = false
      globalSelector.value.cancelEvent()
    }
    // 弹窗确定
    const submitModel = async () => {
      let list = await transformData()
      // console.log('list', list);
      if (!list.length) {
        state.checkPeoples = []
      } else {
        state.checkPeoples = filterData(state.checkPeoples, list)
        console.log('最终渲染数据', state.checkPeoples)
      }
      closeModal()
    }

    // 数据对象去重
    const deduplicateObjects = (arr, key) => {
      return arr.filter((item, index, self) => index === self.findIndex(t => t[key] === item[key]))
    }

    // 弹窗返回过滤数据
    const filterData = (old, newValue) => {
      let result = []
      newValue.forEach(it => {
        let flag = true
        for (let i = 0; i <= old.length; i++) {
          if (old[i] && old[i].dataId == it.dataId) {
            flag = false
            result.push(old[i])
          }
        }
        if (flag) {
          result.push({
            ...it,
            rangData: dataPermissions.value.getCheckState()
          })
        }
      })
      return result
    }

    const handleTanChange = ({ current, pageSize }) => {
      // console.log('数据点击', current, pageSize);
      state.pagination.current = current
      state.currentNum = current
      state.pagination.pageSize = pageSize
      state.pagination.index = current
    }

    // 操作表结构
    const setRangData = (type, item) => {
      console.log('对象看看', item)
      // type: set   remove
      if (type == 'set') {
        state.setRangeItem = item
        state.rangeModalShow = true
      } else if (type == 'remove') {
        state.checkPeoples = state.checkPeoples.filter(i => i.dataId != item.dataId)
        let result = Math.ceil(state.pagination.total / state.pagination.pageSize)
        if (result < state.pagination.current) {
          // console.log('result',result);
          handleTanChange({
            current: result == 0 ? 1 : result,
            pageSize: state.pagination.pageSize
          })
        }
      }
    }
    const modalDataPermissions = ref(null)
    // 设置表结构的数据范围
    const rangeOk = () => {
      let it = modalDataPermissions.value.getCheckState()
      state.checkPeoples.forEach(i => {
        console.log('人员授权列表', i)
        if (Object.keys(state.setRangeItem).length === 0) {
          //批量设置
          state.selectedRowKeys.forEach(ele => {
            if (ele === i.dataId) {
              i.rangData = it
            }
          })
          return
        }
        if (i.dataId == state.setRangeItem.dataId) {
          // 设置单个
          i.rangData = it
        }
      })
      rangeCancel()
    }
    const rangeCancel = () => {
      state.rangeModalShow = false
    }

    // 处理需要提交的数据
    const handleData = () => {
      console.log('数据', dataPermissions.value.getCheckState())
      let data = dataPermissions.value.getCheckState()

      // 默认数据权限自定义处理
      let customPermissionList = []
      data.defaultDataPermissions.length &&
        data.defaultDataPermissions.forEach(i => {
          customPermissionList.push({ departmentId: i.dataId })
        })

      // 人员授权
      let roleStaffDetailList = []
      let roleStaffList = []
      console.log('checkPeoples', state.checkPeoples)
      state.checkPeoples.forEach(i => {
        roleStaffList.push(i.dataId)
        let it = i.rangData.defaultDataPermissions.map(item => {
          return { departmentId: item.dataId }
        })
        roleStaffDetailList.push({
          staffId: i.dataId,
          dataPermission: i.rangData.checked,
          customPermissionList: it
        })
      })

      let params = {
        dataPermission: data.checked,
        customPermissionList,
        roleStaffDetailList,
        roleStaffList
      }
      return params
    }

    // 提交
    const addRole = async () => {
      let params = handleData()
      console.log('最终数据', params)
      // return
      state.loading = true
      const res = await addNewRole({
        roleExplain: modalVal.mark,
        name: modalVal.roleName,
        permissionsIds: modalVal.power,
        // deptIds: modalVal.deptIds,
        dataScope: modalVal.dataScope,
        // roleStaffList: state.checkedIds,
        ...params
      })
      if (res.success) {
        cmsNotice('success', '添加成功')
        router.back()
      }
      state.loading = false
    }
    // 禁止换行
    const lineFeed = e => {
      e.preventDefault()
    }
    //展开树节点
    const onExpand = expandedKeys => {
      // console.log('expandedKeys的值', expandedKeys)
      state.expandedKeys = expandedKeys //展开项
      state.autoExpandParent = false
    }
    //取消
    const handleCancel = () => {
      formRef.value.resetFields()
      router.back()
    }
    //选中
    const onCheck = checkedKeys => {
      modalVal.power = checkedKeys
      console.log('checkedKeys的值', checkedKeys)
      if (!checkedKeys.includes(state.elecId)) {
        if (state.elecId != undefined) {
          checkedKeys.push(state.elecId)
        }
        modalVal.power = checkedKeys
      } else {
        modalVal.power = checkedKeys
      }
      console.log('modalVal.power', modalVal.power)
    }

    const dataPermissions = ref(null)

    // 添加授权人员
    const addPeople = () => {
      getScopeList()
      console.log('选中过的数据', state.checkPeoples)
      modalConfig.modalShow = true
    }

    // 部门子级
    const tolowerMdel = async id => {
      let data = {
        departmentId: id
      }
      getScopeList(data)
    }

    //初始列表
    const getScopeList = async data => {
      let params = {
        selectorType: selectorType.value //1部门人员2部门3员工
        // allData: true
      }
      const res = await getDepartmentOrStaff({ ...params, ...data })
      let list = formateListData(res)
      modalConfig.checkList = list
      console.log('res', list)
    }
    //搜索请求
    const getSearchInfo = async data => {
      let params = {
        selectorType: selectorType.value,
        searchName: data
        // allData: true
      }
      const res = await getSearchData(params)
      let list = formateListData(res)
      modalConfig.checkList = list
      // console.log('搜索到的值res', res)
    }

    const fetchPermissionList = async () => {
      const defalutRole = [
        // 'PC_SEAL_MANAGER_MENU', // 用印管理
        // 'PC_SEAL_APPLY_MENU', // 用印申请
        // 'PC_SEAL_LOG_MENU', // 用印记录
        // 'PC_APPROVAL_RECORD_MENU', // 审批中心
        // 'PC_WAITING_APPROVAL_MENU', // 待审批
        // 'PC_APPROVED_MENU', // 已审批
        'PC_ELECTRONIC_CONTRACT_MENU' // 电子签章-文件管理
        // 'PC_CARBON_COPY_ME_MENU' // 抄送我的
      ]
      function replacePermissionFeild(arr) {
        // console.log('arr的值', arr)
        return arr.map(item => {
          const disabled = defalutRole.includes(item.code)
          if (disabled) {
            defaultRoleIds.value.push(item.id)
          }
          const newItem = { ...item, class: disabled ? 'disabled' : '' }
          if (newItem.child && newItem.child.length) {
            newItem.child = replacePermissionFeild(newItem.child)
          }
          return newItem
        })
      }
      const res = await getPermissionList()
      const eleId = res.data.filter(item => item.url === '/elecSeal')
      console.log('1111', eleId)
      if (eleId.length) {
        state.elecId = eleId[0].child[1].id
        console.log('id', state.elecId)
      }
      state.permissionList = replacePermissionFeild(res.data)
      modalVal.power = [...defaultRoleIds.value] //默认选中的选项
    }

    const getOrganizationalList = async () => {
      // 获取流程范围中部门列表的函数
      const res = await getDepartmentShow()
      state.organizationalList = res.data.length ? res.data : []
    }

    const selectedDepartment = (value, name, allIn) => {
      modalVal.deptIds = value
      state.departmentNames = name
      modalVal.dataScope = allIn
    }

    // 全选
    const onCheckAllChange = e => {
      if (e.target.checked) {
        modalVal.power = dg(state.permissionList)
      } else {
        modalVal.power = [...defaultRoleIds.value]
      }
    }
    const dg = arr => {
      let result = []
      if (arr.length == 0) return []
      if (arr.length) fn(arr)
      function fn(arr) {
        arr.forEach(val => {
          result.push(val.id)
          if (val.child) {
            fn(val.child)
          }
        })
      }
      return result
    }
    // 点击选择角色人员
    const handleDepartment = () => {
      state.RangeModalVisible = true
      state.departmentList = modalVal.roleStaffList
    }
    const handledRangeVisible = e => {
      state.RangeModalVisible = e
    }
    // 人员授权模块变更
    const checkData = e => {
      console.log('选择了人员', e)
      modalVal.roleStaffList = e
    }
    // 删除
    const deleteSta = index => {
      modalVal.roleStaffList.splice(index, 1)
    }
    onMounted(() => {
      fetchPermissionList()
      getOrganizationalList()
    })
    // 全选
    watch(
      () => modalVal.power,
      newValue => {
        const len = dg(state.permissionList).length
        if (len && newValue.length === len) {
          // console.log(1)
          checkAll.value = true
        } else {
          // console.log(2)
          checkAll.value = false
        }
      }
    )
    // 切换数据
    const changeData = ({ isOrganizationData, searchValue }) => {
      console.log('切换的数据的类型', isOrganizationData)
      if (isOrganizationData) {
        // 组织架构数据
        selectorType.value = 1
      } else {
        //人员数据
        selectorType.value = 3
      }
      getSearchInfo(searchValue)
    }
    return {
      comfirmAdd,
      formRef,
      modalVal,
      lineFeed,
      rules,
      onExpand,
      ...toRefs(state),
      onCheck,
      handleCancel,
      addRole,
      selectedDepartment,
      checkAll,
      onCheckAllChange,
      checkData,
      handledRangeVisible,
      handleDepartment,
      deleteSta,
      getSearchInfo,
      tolowerMdel,
      ...toRefs(modalConfig),
      globalSelector,
      dataPermissions,
      closeModal,
      addPeople,
      submitModel,
      handleTanChange,
      setRangData,
      rangeCancel,
      rangeOk,
      modalDataPermissions,
      onSelectChange,
      batchSetting,
      onSelectAll,
      changeData,
      selectorType
    }
  }
})
</script>

<style lang="scss" scoped>
.search-input {
  width: 470px;
  height: 36px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}
.te-power {
  max-height: 300px;
  overflow-y: scroll;
  :deep(.ant-checkbox-wrapper) {
    display: block;
    margin-left: 0;
  }
  :deep(.ant-tree-switcher) {
    left: 135px;
    transition: 0.3s;
  }
  :deep(.ant-tree-switcher_open) {
    transform: rotateZ(90deg);
  }
  :deep(li span.ant-tree-switcher, .ant-tree li span.ant-tree-iconEle) {
    width: 12px;
  }
}

:deep(.ant-form-item-label) {
  min-width: 82px;
}
.form-long {
  margin-bottom: 16px;
  :deep(.ant-form-item-label) {
    margin-left: -14px;
    line-height: 20px;
  }
}

// :deep(.ant-tree) {
//   max-height: 300px;
//   overflow-y: scroll;
// }
.power {
  padding-left: 42px;
  display: flex;
  line-height: 20px;
  > div {
    display: inline-block;
  }
}
.line-tooltip {
  :deep(.ant-tooltip-content) {
    width: 360px;
  }
}
.charts-icon {
  font-size: 14px;
  color: #333333;
  cursor: pointer;
}
.powers {
  line-height: 20px;
  :deep(.ant-form-item-control-wrapper) {
    width: 480px;
  }
}
.business-role-add {
  :deep(.disabled) {
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      width: 35px;
      height: 100%;
    }
    .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
      background-color: rgba(0, 0, 0, 0.04);
      border: 1px solid rgba(0, 0, 0, 0.15);
      &::after {
        border-color: rgba(0, 0, 0, 0.25);
      }
    }
  }
}
.form-tips-view {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.role-box {
  width: 540px;
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  line-height: 32px;
  color: #bfbfbf;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  margin-right: 8px;
  padding: 6px 24px 1px 10px;
  transition: border-color 0.3s;
  &::-webkit-scrollbar {
    display: block;
  }
  &:hover {
    border-color: #cf3838;
  }
  .role-tag {
    // width: 90px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    padding: 0 5px 0 7px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .role-name {
    color: rgba(0, 0, 0, 0.85);
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 7px;
    white-space: nowrap;
    display: inline-block;
    flex: 1;
    cursor: default;
  }
  .role-close {
    width: 14px;
    height: 14px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: #333;
    }
  }
  .down-arrow {
    position: absolute;
    font-size: 12px;
    top: 10px;
    right: 11px;
    color: rgba(0, 0, 0, 0.3);
  }
}

.page-add .form-item-width,
.printer-seal {
  width: 781px;
}

.check-box {
  width: 863px;
  background: #f2f2f2;
  position: relative;
  // padding-bottom: 2px;

  .top-title {
    line-height: 22px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    margin-top: -20px;
  }
  .add-check-box {
    position: absolute;
    top: 9px;
    right: 24px;
    z-index: 999;
    color: #0A7BFF;
    line-height: 22px;
    font-size: 14px;
    cursor: pointer;
  }
  .operation-button {
    width: 72px;
    height: 26px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #0A7BFF;
    font-weight: 400;
    font-size: 14px;
    color: #0A7BFF;
    line-height: 22px;
    padding: 0;
  }
  .operation-button:nth-of-type(1) {
    position: absolute;
    top: 7px;
    left: 192px;
  }
  .operation-button:nth-of-type(2) {
    position: absolute;
    top: 7px;
    left: 272px;
  }
}

:deep(.ant-tabs-top-bar) {
  border-bottom: 0px;
  .ant-tabs-nav {
    margin-left: 24px;
  }

  .ant-tabs-tab {
    padding: 10px 0 10px;
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-tabs-tab-active {
    color: #0A7BFF;
  }
}

:deep(.ant-tabs-bar) {
  margin-bottom: 0px;
  border-bottom: 1px solid #f2f2f2;
}
.add-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.people-box {
  min-height: 446px;
  background: #f7f7f7;

  .no-check-box {
    color: #0A7BFF;
    font-size: 14px;
    padding: 5px 10px;
    margin-top: -42px;
    border: 1px dashed #0A7BFF;
    cursor: pointer;
  }

  .table-box {
    padding: 0 24px;
    // background-color: skyblue;
    :deep(.ant-table-thead) {
      .ant-table-row-cell-break-word {
        background-color: #ebebeb;
      }
    }

    :deep(.ant-table-row-cell-break-word) {
      background-color: #f7f7f7;
    }
  }
  :deep(.ant-table-thead > tr:first-child > th:first-child) {
    background-color: #ebebeb;
  }
  :deep(.ant-table-tbody > tr > td.ant-table-selection-column) {
    background-color: #f7f7f7;
  }

  /deep/.selector-content {
    border: none;
    .select-left {
      padding: 16px 14px 16px 45px;
    }
    .show-right {
      padding: 20px 45px 24px 25px;
    }
    .next {
      margin-right: 10px;
    }
  }
  /deep/.max-12 {
    max-width: 8em !important;
  }
}
.icon-tianjiatiaojian {
  font-size: 14px;
  color: #0A7BFF;
}
.check-btn {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #1890ff;
  line-height: 20px;
  margin-right: 16px;
}
.headlin-text {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  margin-bottom: 16px;
}

.text-6 {
  max-width: 84px;
  display: block;
}
.text-168 {
  max-width: 168px;
}
.text-140 {
  max-width: 140px;
}
.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
